import {IGptBuddy} from "./IGptBuddy";
import {ChatMessageRole} from "../ChatPanel/ChatMessage";
import {AbstractGptBuddy} from "./AbstractGptBuddy";
import {Message} from "../ChatPanel/ChatOutput";
import chatCompletions from "../api";
import {SendChatOptions} from "../utils/streamUtils";

class Gpt4Buddy extends AbstractGptBuddy implements IGptBuddy {
    SYSTEM_CONTEXT = `Imagine you and I are collaborating on the system design for a software architecture. I will tell you what I am trying to build. If the requirements are unclear, ask me clarifying questions. I may also ask you for ideas on how to build the system during our conversation.

It's crucial that you follow the exact format provided below for each response:
1. Write your response to my question or statement. Do not include a Mermaid diagram in this part.
2. On a new line, insert a line of five equal signs (=====) to separate your response from the Mermaid diagram.
3. On a new line, provide a Mermaid diagram representing the current state of the architecture based on our conversation. Wrap the mermaid diagram in three backticks (\`\`\`). Do not include any other text after the five equal signs (=====) except for the mermaid diagram.

Please adhere to these guidelines for Mermaid diagrams:
- Do not use parentheses in the component names. Use square brackets [] or curly braces {} instead.
- Use the database icon (indicated by a capital D) when rendering a database.
- Make sure there is no non-Mermaid text after the diagram.

Example:
#Your response
graph LR
A[Component A] --> B[Component B]

Here's a Mermaid diagram with a database example:
#Your response
graph LR
A[Component A] --> D{Database}

Please ensure you maintain the format exactly as shown in these examples and adhere to the provided guidelines.`;
    FORMAT_REMINDER = `Please don't forget to follow the format as we discussed earlier:
1. Write your response to my question or statement without a Mermaid diagram.
2. Separate your response from the Mermaid diagram with five equal signs (${this.BREAK_TOKEN}).
3. Provide the Mermaid diagram representing the current state of the architecture based on our conversation. Wrap the mermaid diagram in three backticks (\`\`\`). Do not include any other text after the five equal signs (=====) except for the mermaid diagram.`;

    DIAGRAM_ERROR = `It seems there is an error in your last Mermaid diagram, or you forgot to include it. Please correct the mistake and remember to follow the format we discussed earlier:
1. Write your response to my question or statement. Do not include a Mermaid diagram.
2. On a new line, insert a line of five equal signs (${this.BREAK_TOKEN}).
3. On a new line, provide a Mermaid diagram representing the current state of the architecture based on our conversation. Wrap the mermaid diagram in three backticks (\`\`\`). Do not include any other text after the five equal signs (=====) except for the mermaid diagram.

Please adhere to these guidelines for Mermaid diagrams:
- Do not use parentheses in the component names except for representing databases. Use square brackets [] or curly braces {} for other components.
- Use parentheses () when rendering a database.
- Make sure there is no non-Mermaid text after the diagram.

Example:
Your response
graph LR
A[Component A] --> B[Component B]

Here's a Mermaid diagram with a database example:
Your response
graph LR
A[Component A] --> B[(Database)]

Please ensure you maintain the format exactly as shown in these examples and adhere to the provided guidelines.

Here is the error message from your mermaid diagram:`

    usesFormatReminder = () => true
    initialMessages = [
        {
            role: ChatMessageRole.SYSTEM,
            content: this.SYSTEM_CONTEXT
        },
        {
            role: ChatMessageRole.ASSISTANT,
            content: this.DUCKY_INTRO
        }
    ]

    formatDiagram = (diagram: string) => {
        if (!diagram) return diagram
        if (diagram.startsWith("graph")) {
            return diagram
        }
        if (diagram.startsWith("mermaid")) {
            return diagram.replace("mermaid", "")
        }
        if (diagram.startsWith("Mermaid")) {
            return diagram.replace("Mermaid", "")
        }
        if (diagram.trim().startsWith("```mermaid") && diagram.trim().endsWith("```")) {
            return diagram.replace("```mermaid", "").replace("```", "").trim()
        }
        if (diagram.trim().startsWith("```Mermaid") && diagram.trim().endsWith("```")) {
            return diagram.replace("```Mermaid", "").replace("```", "").trim()
        }
        if (diagram.trim().startsWith("```") && diagram.trim().endsWith("```")) {
            return diagram.replaceAll("```", "").trim()
        }
        if (diagram.trim().endsWith('```')) {
            return diagram.replaceAll("```", "").trim()
        }
        let mermaidPieces = diagram.split("```")
        if (mermaidPieces.length > 0) {
            return mermaidPieces[1].trim()
        }
        return diagram.trim()
    }

    sendChat = (
        messages: Message[],
        options: SendChatOptions,
        gptInstance: IGptBuddy,
        addFormatReminder: (messages: Message[], gptInstance: IGptBuddy) => void,
        eIsValid: (e: { data: string }) => "" | number,
        eIsDoneToken: (e: { data: string }) => boolean,
        responseContentIsValid: (response: { choices: string | any[] }) => "" | 0,
        token: string | undefined
    ): void => {
        const {onMessageChunkReceived, onMermaidChunkReceived, onStreamComplete} = options
        const messagesToSend = Object.assign([], messages)
        addFormatReminder(messagesToSend, gptInstance)
        const chatGptStream = chatCompletions(messagesToSend, token)
        let haveSeenSeparatorToken = false
        chatGptStream.addEventListener('message', (e: {data: string}) => {
            if (!eIsValid(e)) return
            if (eIsDoneToken(e)) {
                onStreamComplete()
                return
            }

            const response = JSON.parse(e.data);
            if (!responseContentIsValid(response)) return

            const newContent = response.choices[0].delta.content
            if (newContent.includes(gptInstance.BREAK_TOKEN) || newContent.includes('``')) {
                haveSeenSeparatorToken = true
                if (newContent.includes('``')) {
                    onMermaidChunkReceived(newContent)
                }
            } else if (!haveSeenSeparatorToken) {
                onMessageChunkReceived(newContent)
            } else {
                onMermaidChunkReceived(newContent)
            }
        })
        chatGptStream.stream()
    }
}

export default Gpt4Buddy