import React, {useEffect, useState} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {Route, Routes} from "react-router";
import HomePage from "./pages/HomePage";
import CallbackPage from "./pages/CallbackPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import AccountPage, {Plan} from "./pages/AccountPage";
import CheckoutPage from "./stripe/stripe";
import DesignDuckyAuthContext, {Auth0Jwt, CLAIMS, DesignDuckyAuth} from "./designDuckyAuthProvider";
import jwtDecode from "jwt-decode";

import ReactGA from 'react-ga';
ReactGA.initialize('370531359');

function App() {
    const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [designDuckyAuthContext, setDesignDuckyAuthContext] = useState<DesignDuckyAuth | null>(null)
    const [isLoadingContext, setIsLoadingContext] = useState(true)

    useEffect(() => {
        const getAccessTokenHelper = async () => {
            if (!isAuthenticated) {
                console.debug(`Plan: ${Plan.FREE_GPT3}`)
                setDesignDuckyAuthContext({
                    token: undefined,
                    plan: Plan.FREE_GPT3,
                    stripeCustomerId: undefined,
                    planExpireDate: undefined
                })
                return
            }
            let token = await getAccessTokenSilently()
            let decoded = jwtDecode<Auth0Jwt>(token)
            // @ts-ignore
            const stripeCustomerId = decoded[CLAIMS.STRIPE_CUSTOMER_ID]
            // @ts-ignore
            const trialStartDate = decoded[CLAIMS.TRIAL_START_DATE]
            // @ts-ignore
            const paidStartDate = decoded[CLAIMS.PAID_START_DATE]

            let plan = Plan.FREE_GPT3
            let planExpireDate = undefined;
            if (trialStartDate && !paidStartDate) {
                const EXPIRATION_MINUTES = 30
                const trialStartDateDateObject = new Date(trialStartDate)
                const trialExpirationDate = new Date(trialStartDateDateObject.getTime() + EXPIRATION_MINUTES*60000);
                const currentDateTime = new Date()
                if (currentDateTime > trialExpirationDate) {
                    plan = Plan.TRIAL_GPT4_EXPIRED
                } else {
                    plan = Plan.TRIAL_GPT4_ACTIVE
                }
                planExpireDate = trialExpirationDate
            } else if (paidStartDate) {
                const EXPIRATION_MINUTES = 43200 // 30 days
                const paidStartDateDateObject = new Date(paidStartDate)
                const paidExpirationDate = new Date(paidStartDateDateObject.getTime() + EXPIRATION_MINUTES*60000);
                const currentDateTime = new Date()
                if (currentDateTime > paidExpirationDate) {
                    plan = Plan.PAID_GPT4_EXPIRED
                } else {
                    plan = Plan.PAID_GPT4_ACTIVE
                }
                planExpireDate = paidExpirationDate
            }

            console.debug(`Plan: ${plan}`)
            setDesignDuckyAuthContext({
                token,
                plan,
                stripeCustomerId,
                planExpireDate
            })
        }
        getAccessTokenHelper()
    }, [isLoading])

    if (isLoading) {
        return (
            <>Loading!</>
        );
    }
    return (
        <DesignDuckyAuthContext.Provider value={designDuckyAuthContext}>
            <Routes>
                <Route path="/" element={<HomePage/>} />
                <Route path="/callback" element={<CallbackPage />} />
                <Route path="/account" element={<AccountPage/>} />
                <Route path="/account/success" element={<AccountPage/>} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </DesignDuckyAuthContext.Provider>
    );
}

export default App;
