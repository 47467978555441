import React, {useState} from 'react'
import MermaidRenderedDiagram from "./MermaidRenderedDiagram";
import MermaidCode from "./MermaidCode";

interface Props {
    mermaid: string;
    clearChat: () => void;
}

enum ShowMode {
    RENDERED,
    CODE,
    BOTH
}

const MermaidPanel: React.FC<Props> = (props: Props) => {
    const { mermaid, clearChat } = props
    const [showMode, setShowMode] = useState(ShowMode.RENDERED)
    const onClickShowCode = () => {
        setShowMode(ShowMode.CODE)
    }
    const onClickShowDiagram = () => {
        setShowMode(ShowMode.RENDERED)
    }
    const onClickShowBoth = () => {
        setShowMode(ShowMode.BOTH)
    }

    const onClickClearChat = () => {
        clearChat()
    }

    let mermaidContents
    switch (showMode) {
        case ShowMode.RENDERED:  {
            mermaidContents = (
                <MermaidRenderedDiagram mermaidDiagram={mermaid}/>
            )
            break
        }
        case ShowMode.CODE:  {
            mermaidContents = (
                <MermaidCode mermaid={mermaid}/>
            )
            break
        }
        case ShowMode.BOTH:  {
            mermaidContents = (
                <>
                    <MermaidRenderedDiagram mermaidDiagram={mermaid}/>
                    <MermaidCode mermaid={mermaid}/>
                </>
            )
            break
        }
    }

    return (
        <>
            <div className={"mermaid-contents"}>
                {mermaidContents}
            </div>
            <div className={"mermaid-options"}>
                <button onClick={onClickClearChat}>Clear chat</button>
                <button onClick={onClickShowCode}>Code</button>
                <button onClick={onClickShowDiagram}>Diagram</button>
                <button onClick={onClickShowBoth}>Both</button>
            </div>
        </>
    )
}

export default MermaidPanel