import React, {useState, useEffect, useContext} from "react";
import PageLayout from "../PageLayout";
import {useAuth0} from "@auth0/auth0-react";
import DesignDuckyAuthContext from "../designDuckyAuthProvider";

const ProductDisplay = ({ stripeCustomerId }) => (
    <PageLayout>
        <div className="checkout-container">
            <p className="title">30 days of GPT4-powered Design Ducky for $2</p>
            <p className="description">With this purchase you will have access to use Design Ducky powered by the GPT4 model for 30 days*. This is not a subscription! It is a one-time payment. The checkout flow is powered by Stripe.</p>
            <form action={`https://api.designducky.ai/create-checkout-session?stripe_customer_id=${stripeCustomerId}`} method="POST">
                <button type="submit">
                    Take me to stripe to checkout
                </button>
            </form>
            <p className="terms">*Access to GPT4 model is limited by GPT4 model rate limiting and subject to API availability. Design Ducky team is not responsible for OpenAI availability and cannot guarantee uptime.</p>
        </div>
    </PageLayout>
);

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function CheckoutPage() {
    const [message, setMessage] = useState("");
    const designDuckyAuth = useContext(DesignDuckyAuthContext)
    const stripeCustomerId = designDuckyAuth.stripeCustomerId
    const auth0 = useAuth0()

    useEffect(() => {

        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, [auth0]);

    return message ? (
        <Message message={message} />
    ) : (
        <ProductDisplay stripeCustomerId={stripeCustomerId}/>
    );
}