import Header from "./Header";
import React, {ReactNode} from "react";

interface Props {
    children: ReactNode
}
const PageLayout: React.FC<Props> = ({children}) => {
    return (
        <div className="container">
            <>
                <Header/>
                {children}
                <span style={{color: '#8a8a8a', fontSize: '10px', textAlign: 'center'}}>
                    Thank you Victoruler for the <a style={{marginBottom: '-20px', color: '#8a8a8a', fontSize: '10px'}} href="https://www.flaticon.com/free-icons/duck" title="duck icons">duck icon! </a>
                    Design Ducky is brought to you by the Design Ducky team. 2023. <a style={{marginBottom: '-20px', color: '#8a8a8a', fontSize: '10px'}} href={"mailto:exoascension@gmail.com"}>Contact Us</a>.
                </span>
            </>
        </div>
    )
}

export default PageLayout