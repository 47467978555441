import React, {useContext} from "react";
import PageLayout from "../PageLayout";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import DesignDuckyAuthContext from "../designDuckyAuthProvider";

enum Plan {
    FREE_GPT3 = "Free GPT3",
    TRIAL_GPT4_ACTIVE = "Trial GPT4",
    TRIAL_GPT4_EXPIRED = "Trial GPT4 - Expired",
    PAID_GPT4_ACTIVE = "Paid GPT4",
    PAID_GPT4_EXPIRED = "Paid GPT4 - Expired",
}

interface Props {
}

const AccountPage: React.FC<Props> = () => {
    const { user } = useAuth0()
    const designDuckyAuth = useContext(DesignDuckyAuthContext)
    const navigate = useNavigate()

    if (!user || !designDuckyAuth) {
        navigate("/")
        return <></>
    }

    let planInfo
    switch (designDuckyAuth.plan) {
        case Plan.FREE_GPT3: {
            planInfo = (
                <>
                    <p>You are currently using Design Ducky for free with the GPT3 model.</p>
                </>
            )
            break
        }
        case Plan.TRIAL_GPT4_ACTIVE: {
            // @ts-ignore
            let minutesLeft = Math.floor((designDuckyAuth.planExpireDate! - new Date())/1000/60)
            if (minutesLeft > 0) {
                planInfo = (
                    <>
                        <p>You are currently using Design Ducky's 30-minute trial with the GPT4 model. When your trial expires, you will automatically downgrade to the GPT3 model.</p>
                        <p>You have {minutesLeft} minutes left.</p>
                        <p>Want to keep using GPT4?</p>
                        <button onClick={() => navigate("/checkout")}>Upgrade</button>
                    </>
                )
            } else {
                planInfo = (
                    <>
                        <p>You 30-minute trial with the GPT4 model has expired.</p>
                        <p>Want to keep using GPT4?</p>
                        <button onClick={() => navigate("/checkout")}>Upgrade</button>
                    </>
                )
            }

            break
        }
        case Plan.TRIAL_GPT4_EXPIRED: {
            planInfo = (
                <>
                    <p>Oh no! Your trial using the GPT4 model has expired. :(</p>
                    <p>What to go back to GPT4?</p>
                    <button onClick={() => navigate("/checkout")}>Upgrade</button>
                </>
            )
            break
        }
        case Plan.PAID_GPT4_EXPIRED: {
            planInfo = (
                <>
                    <p>Oh no! Your access to the GPT4 model has expired. :(</p>
                    <p>What to go back to GPT4?</p>
                    <button onClick={() => navigate("/checkout")}>Upgrade</button>
                </>
            )
            break
        }
        case Plan.PAID_GPT4_ACTIVE: {
            planInfo = (
                <>
                    <p>You have purchased access to GPT4 powered Design Ducky.</p>
                    <p>Happy designing!</p>
                    <button onClick={() => navigate("/")}>Start Chatting</button>
                </>
            )
            break
        }
    }

    return (
        <PageLayout>
                <div className={"account-page-container"}>
                    {planInfo}
                    <p>Need support? <a href={"mailto:exoascension@gmail.com"}>Email Us</a></p>
                    <p className={"loggedInText"}>You are logged in as {user.email || user.sub}</p>
                </div>
        </PageLayout>
    );
};

export { AccountPage, Plan }
export default AccountPage