import {AppState, Auth0Provider, User} from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    children: JSX.Element | JSX.Element[]
}

export const Auth0ProviderWithNavigate: React.FC<Props> = (props: Props)  => {
    const { children } = props
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

    const onRedirectCallback = (appState: AppState | undefined, user?: User | undefined) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: "api.designducky.ai",
                scope: "read:current_user update:current_user_metadata"
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};