import React, {useEffect, useRef} from 'react'
import mermaid from "mermaid";

interface Props {
    mermaidDiagram: string;
}

const MermaidRenderedDiagram: React.FC<Props> = (props: Props) => {
    const  { mermaidDiagram } = props
    const diagramRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        mermaid.initialize({
            startOnLoad: false,
            theme: 'default'
        });
    }, [])

    useEffect(() => {
        if (diagramRef.current) {
            diagramRef.current.removeAttribute("data-processed")
            mermaid.run()
        }
    }, [diagramRef, mermaidDiagram])

    return (
        <pre id={"mermaid-diagram-container"} className={"rendered-mermaid mermaid"} ref={diagramRef}>
            {mermaidDiagram}
        </pre>
    );
}

export default MermaidRenderedDiagram