import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

enum ChatMessageRole {
    USER = "user",
    ASSISTANT = "assistant",
    SYSTEM = "system",
}
interface Props {
    role: ChatMessageRole,
    message: string
}

const ChatMessage: React.FC<Props> = (props: Props) => {
    const { role, message } = props
    const [imageError, setImageError] = useState(false)
    const { user } = useAuth0()
    const onImageError = () => {
        setImageError(true)
    }
    let nameTag = <span className={"chat-message-role"}>{role}:</span>
    // if (role === ChatMessageRole.USER) {
    //     if (user) {
    //         const { name, picture } = user
    //         if (picture && !imageError) {
    //             nameTag = <><img src={picture} height={"30px"} style={{verticalAlign: 'middle'}} alt="ChatMessage" onError={onImageError}/>:</>
    //         } else if (name) {
    //             nameTag = <span className={"chat-message-role"}>{name}:</span>
    //         }
    //     } else {
    //         nameTag = <span className={"chat-message-role"}>{role}:</span>
    //     }
    // } else {
    //     nameTag = <span className={"chat-message-role"}>{role}:</span>
    // } todo

    return (
        <p className={`chat-message ${role === ChatMessageRole.ASSISTANT ? 'chat-message-assistant' : ''} ${role === ChatMessageRole.USER ? 'chat-message-user' : ''}`}>
            {nameTag} {message}
        </p>
    )
}

export {ChatMessageRole, ChatMessage}
export default ChatMessage