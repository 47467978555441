import {Message} from "../ChatPanel/ChatOutput";
import {IGptBuddy} from "./IGptBuddy";
import {SendChatOptions} from "../utils/streamUtils";

export abstract class AbstractGptBuddy implements IGptBuddy {
    DUCKY_INTRO = `Hi! I'm Design Ducky, here to pair with you on system design. As you chat with me, I'll keep the diagram on the right up to date. What do you want to build?`;
    BREAK_TOKEN = '=====';
    getDiagramErrorPrompt = (errorMessage: string) => {
        return `${this.DIAGRAM_ERROR} "${errorMessage}"`
    }
    abstract DIAGRAM_ERROR: string;
    abstract FORMAT_REMINDER: string;
    abstract SYSTEM_CONTEXT: string;
    abstract usesFormatReminder: () => boolean;
    abstract initialMessages: Message[];
    abstract sendChat: (
        messages: Message[],
        options: SendChatOptions,
        gptInstance: IGptBuddy,
        addFormatReminder: (messages: Message[], gptInstance: IGptBuddy) => void,
        eIsValid: (e: { data: string }) => "" | number,
        eIsDoneToken: (e: { data: string }) => boolean,
        responseContentIsValid: (response: { choices: string | any[] }) => "" | 0,
        token: string | undefined
    ) => void

    abstract formatDiagram: (diagram: string) => string
}
