import React, {useRef} from "react";
import ChatMessage, {ChatMessageRole} from "./ChatMessage";


type Message = {
    role: ChatMessageRole,
    content: string
}
interface Props {
    messages: Message[]
}

const ChatOutput: React.FC<Props> = (props: Props) => {
    const { messages } = props
    const outputDivRef = useRef<HTMLInputElement>(null)
    if (outputDivRef && outputDivRef.current) {
        outputDivRef.current.scrollTop = outputDivRef.current.scrollHeight
    }
    return (
        <div className={"chat-output display-linebreak"} ref={outputDivRef}>
            {messages.map((message, index) => (
                <ChatMessage key={index} role={message.role} message={message.content}/>
            ))}
        </div>
    )
}

export type { Message };
export default ChatOutput