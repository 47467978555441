import React, {useContext, useEffect, useState} from 'react'
import ChatPanel from "./ChatPanel/ChatPanel";
import MermaidPanel from "./MermaidPanel/MermaidPanel";
import {ChatMessageRole} from "./ChatPanel/ChatMessage";
import {Message} from "./ChatPanel/ChatOutput";
import {useAuth0} from "@auth0/auth0-react";
import Gpt3_5Buddy from "./GptBuddy/Gpt3_5Buddy";
import streamUtils from "./utils/streamUtils";
import mermaid from "mermaid";
import localStorageUtil from "./utils/localStorageUtil";
import {IGptBuddy} from "./GptBuddy/IGptBuddy";
import DesignDuckyAuthContext, {DesignDuckyAuth} from "./designDuckyAuthProvider";
import {Plan} from "./pages/AccountPage";
import Gpt4Buddy from "./GptBuddy/Gpt4Buddy";
import ReactGA from "react-ga";

const sampleDiagram = `
graph LR
A[Frontend] --> B[Backend]
B --> C[(Database)]
`

const MESSAGE_LIMIT = 5;

function getStarterMessages(gptInstance: IGptBuddy): Message[] {
    const storedChat = localStorageUtil.getStoredChat()
    if (storedChat && storedChat.length) {
        const savedChatWithLatestContext: Message[] = Object.assign([], storedChat);
        savedChatWithLatestContext[0] = gptInstance.initialMessages[0]
        return savedChatWithLatestContext
    }
    return gptInstance.initialMessages
}

function getStarterDiagram(): string {
    const storedDiagram = localStorageUtil.getStoredDiagram()
    if (storedDiagram && storedDiagram.length) {
        return storedDiagram
    }
    return sampleDiagram
}

const determineGptInstance = (designDuckyAuth: DesignDuckyAuth | null) => {
    if (!designDuckyAuth) {
        return new Gpt3_5Buddy()
    }
    if (designDuckyAuth.plan === Plan.FREE_GPT3 || designDuckyAuth.plan === Plan.PAID_GPT4_EXPIRED || designDuckyAuth.plan === Plan.TRIAL_GPT4_EXPIRED) {
        return new Gpt3_5Buddy()
    }
    return new Gpt4Buddy()

}

const MainContent: React.FC = () => {
    const designDuckyAuth = useContext(DesignDuckyAuthContext)
    const gptInstance = determineGptInstance(designDuckyAuth)

    useEffect(() => {
        const internalMessagesWithLatestContext: Message[] = Object.assign([], internalMessages);
        internalMessagesWithLatestContext[0] = gptInstance.initialMessages[0]
        setInternalMessages(internalMessagesWithLatestContext)
    }, [designDuckyAuth])


    const [messageCount, setMessageCount] = useState(localStorageUtil.getMessageCount());
    const [internalMessages, setInternalMessages] = useState(getStarterMessages(gptInstance))
    const [mermaidToRender, setMermaidToRender] = useState(getStarterDiagram())
    const {isAuthenticated} = useAuth0()

    const updateInternalMessages = (messages: Message[]) => {
        localStorageUtil.updateStoredChat(messages)
        setInternalMessages(messages)
    }

    const updateMermaidToRender = (mermaidDiagram: string) => {
        console.log("NEW MERMAID TO RENDER")
        console.log(mermaidDiagram)
        localStorageUtil.updateStoredDiagram(mermaidDiagram)
        setMermaidToRender(mermaidDiagram)
    }

    const clearChat = () => {
        updateInternalMessages(gptInstance.initialMessages)
        updateMermaidToRender(sampleDiagram)
    }

    const onMessageSubmit = async (message: string) => {
        ReactGA.event({
            category: 'Chat Panel',
            action: 'User submit message'
        });

        // copy messages to work with
        const newMessages: Message[] = Object.assign([], internalMessages);

        // add the users new message to be rendered
        newMessages.push({
            role: ChatMessageRole.USER,
            content: message
        })
        updateInternalMessages(newMessages)

        // start rendering the new chat gpt response
        newMessages.push({
            role: ChatMessageRole.ASSISTANT,
            content: ''
        })
        updateInternalMessages(newMessages)

        const token = designDuckyAuth?.token
        let mermaidDiagram = ''
        streamUtils.sendChat(
            newMessages,
            {
                onMessageChunkReceived: (messageChunk: string) => {
                    const clonedMessages: Message[] = Object.assign([], newMessages);
                    const latestMessage = clonedMessages[clonedMessages.length - 1].content
                    clonedMessages[clonedMessages.length - 1].content = latestMessage + messageChunk
                    updateInternalMessages(clonedMessages)
                },
                onMermaidChunkReceived: async (mermaidChunk: string) => {
                    mermaidDiagram += mermaidChunk
                    let formattedPartialDiagram = mermaidDiagram.trim() // todo
                    if (mermaidDiagram.startsWith("```mermaid")) {
                        formattedPartialDiagram = formattedPartialDiagram.replace("```mermaid", "")
                    } else if (mermaidDiagram.startsWith("```Mermaid")) {
                        formattedPartialDiagram = formattedPartialDiagram.replace("```Mermaid", "")
                    } else if (mermaidDiagram.startsWith("```")) {
                        formattedPartialDiagram = formattedPartialDiagram.replace("```", "")
                    }
                    if (formattedPartialDiagram.trim().endsWith("```")) {
                        formattedPartialDiagram.replaceAll("```", "")
                    }
                    if (formattedPartialDiagram.trim().endsWith("``")) {
                        formattedPartialDiagram.replaceAll("``", "")
                    }
                    const mermaidParseError = await getMermaidParseErrorIfAny(formattedPartialDiagram)
                    if (!mermaidParseError) {
                        setMermaidToRender(formattedPartialDiagram)
                    }
                },
                onStreamComplete: async () => {
                    console.log("Here is the result of the mermaid stream")
                    console.log(mermaidDiagram)
                    let formattedDiagram = gptInstance.formatDiagram(mermaidDiagram)
                    console.log("here is the formatted diagram")
                    console.log(formattedDiagram)
                    let fixAttempts = 0
                    const MAX_ATTEMPTS = 3
                    let diagramTemp = formattedDiagram
                    while (fixAttempts < MAX_ATTEMPTS) {
                        const mermaidParseError = await getMermaidParseErrorIfAny(diagramTemp)
                        if (!mermaidParseError) {
                            break
                        } else {
                            diagramTemp = gptInstance.formatDiagram(await getNewMermaidDiagram(mermaidParseError, newMessages, gptInstance, token))
                            console.log(diagramTemp)
                            fixAttempts ++
                        }
                    }
                    updateMermaidToRender(gptInstance.formatDiagram(diagramTemp))
                }
            },
            gptInstance,
            token
        )

        if (!isAuthenticated) {
            localStorageUtil.incrementMessageCount();
            setMessageCount(localStorageUtil.getMessageCount());
        }
    }

    const messagesToRender = internalMessages.filter(message => message.role !== ChatMessageRole.SYSTEM)

    return (
        <div className={"main-content"}>
            <div className="left-container">
                <ChatPanel messages={messagesToRender} onMessageSubmit={onMessageSubmit}/>
            </div>
            <div className="right-container">
                <MermaidPanel mermaid={mermaidToRender} clearChat={clearChat}/>
            </div>
        </div>
    )
}

const getMermaidParseErrorIfAny = async (diagram: string): Promise<string | undefined> => {
    try {
        await mermaid.parse(diagram)
    } catch (e: any) {
        console.debug(`Invalid diagram provided: ${diagram}`)
        return e
    }
    return undefined
}

const getNewMermaidDiagram = async (error: string, messages: Message[], gptInstance: IGptBuddy, token: string | undefined): Promise<string> => {
    return streamUtils.getNewDiagram(messages, gptInstance, error, token)
}

export default MainContent