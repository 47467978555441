import duck from "./duck.png";
import AccountMenu from "./AccountMenu/AccountMenu";
import React from "react";
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate()
    const onClickDucky = () => navigate("/")
    return (
        <div className={"header"}>
            <img className={"ducky-icon"} onClick={onClickDucky} height="35px" alt="duck logo" src={duck}/>
            <AccountMenu/>
        </div>
    )
}

export default Header