import {Message} from "../ChatPanel/ChatOutput";

function getMessageCount() {
    return parseInt(localStorage.getItem('messageCount') || '0', 10);
}

function incrementMessageCount() {
    const currentCount = getMessageCount();
    localStorage.setItem('messageCount', `${currentCount + 1}`);
}

function updateStoredChat(messages: Message[]) {
    try {
        localStorage.setItem('duckychat', JSON.stringify(messages));
    } catch (e) {
        console.debug(`Unable to store latest chat state due to: ${e}`)
    }
}

function getStoredChat() {
    try {
        const chat = localStorage.getItem('duckychat')
        if (!chat) return []
        return JSON.parse(chat);
    } catch (e) {
        return []
    }
}

function updateStoredDiagram(mermaid: string) {
    try {
        localStorage.setItem('duckygram', mermaid);
    } catch (e) {
        console.debug(`Unable to store latest diagram state due to: ${e}`)
    }
}

function getStoredDiagram(): string {
    try {
        const diagram = localStorage.getItem('duckygram')
        if (!diagram) return ''
        return diagram;
    } catch (e) {
        return ''
    }
}

const localStorageUtil = {
    incrementMessageCount,
    getMessageCount,
    getStoredChat,
    updateStoredChat,
    getStoredDiagram,
    updateStoredDiagram
}

export default localStorageUtil