import { createContext } from "react";
import {Plan} from "./pages/AccountPage";
import {JwtPayload} from "jwt-decode";

export const CLAIMS = {
    STRIPE_CUSTOMER_ID: 'https://designducky.ai/app_metadata.stripeCustomerId',
    TRIAL_START_DATE: 'https://designducky.ai/app_metadata.trialStartDate',
    PAID_START_DATE: 'https://designducky.ai/app_metadata.paidStartDate'
}

interface Auth0Jwt extends JwtPayload {
    permissions: string[],
}

interface DesignDuckyAuth {
    token: string | undefined;
    plan: Plan;
    stripeCustomerId: string | undefined;
    planExpireDate: Date | undefined;
}

const DesignDuckyAuthContext = createContext<DesignDuckyAuth | null>(null);

export default DesignDuckyAuthContext
export type { Auth0Jwt, DesignDuckyAuth }