import React, {useContext, useState} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {LogoutButton} from "../auth/LogoutButton";
import {LoginButton} from "../auth/LoginButton";
import {useNavigate} from "react-router-dom";
import DesignDuckyAuthContext from "../designDuckyAuthProvider";

const AccountMenu: React.FC = (p) => {
    const { user, isAuthenticated } = useAuth0();
    const [imageError, setImageError] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate()
    const designDuckyAuth = useContext(DesignDuckyAuthContext);


    const onClickMenu = () => {
        setMenuOpen(!menuOpen)
    }

    if (!isAuthenticated || !designDuckyAuth) {
        return (
            <>
                <button className={"profile-button"} onClick={onClickMenu}>
                    <FontAwesomeIcon className={"icon"} icon={faUser} /> Account
                </button>
                { menuOpen && (
                    <div className={"account-menu-options"}>
                        <ul>
                            <li><LoginButton/></li>
                        </ul>
                    </div>
                )}
            </>
        )
    }

    let account
    if (user) {
        const { picture, name } = user
        if (picture && !imageError) {
            account = (
                <img src={picture} height={"30px"} style={{float:'right'}} alt="account" onError={() => setImageError(true)}/>
            )
        } else if (name && name.length) {
            account = (
                <>{name}</>
            )
        } else {
            account = <FontAwesomeIcon icon={faUser}/>
        }
    }
    return (
        <>
            <button className={"profile-button"} onClick={onClickMenu}>
                {account}
            </button>
            { menuOpen && (
                <div className={"account-menu-options"}>
                    <ul>
                        <li><button onClick={() => navigate('/account')}>View Account</button></li>
                        <li><LogoutButton/></li>
                    </ul>
                </div>
            )}
        </>
    )
}

export default AccountMenu