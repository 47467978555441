import React, {useContext} from "react";
import ChatOutput, {Message} from "./ChatOutput";
import ChatInput from "./ChatInput";
import DesignDuckyAuthContext from "../designDuckyAuthProvider";
import {Plan} from "../pages/AccountPage";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

interface Props {
    messages: Message[],
    onMessageSubmit: (message: string) => void;
}

const ChatPanel: React.FC<Props> = (props: Props) => {
    const { messages, onMessageSubmit } = props;
    const designDuckyAuth = useContext(DesignDuckyAuthContext)
    const navigate = useNavigate()
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/",
            },
        });
    };

    const handleUpgrade = async () => {
        navigate('/checkout')
    }

    if (!designDuckyAuth) {
        return <>Loading!</>
    }

    let planInfo
    switch (designDuckyAuth.plan) {
        case Plan.FREE_GPT3: {
            planInfo = (
                <>
                    <p>You are currently using GPT3. The GPT4 model is significantly better at system design and diagramming. <a href={"#"} onClick={handleLogin}>Log in to try it</a>.</p>
                </>
            )
            break
        }
        case Plan.TRIAL_GPT4_ACTIVE: {
            // @ts-ignore
            let minutesLeft = Math.floor((designDuckyAuth.planExpireDate! - new Date())/1000/60)
            if (minutesLeft < 0) {
                planInfo = (
                    <>
                        <p>Your 30-minute trial with the GPT4 has expired. <a href={"#"} onClick={handleUpgrade}>Upgrade</a> to go back to GPT4.</p>
                    </>
                )
            } else {
                planInfo = (
                    <>
                        <p>Your 30-minute trial with GPT4 has {minutesLeft} minutes left. <a href={"#"} onClick={handleUpgrade}>Upgrade</a> to keep GPT4.</p>
                    </>
                )
            }

            break
        }
        case Plan.TRIAL_GPT4_EXPIRED: {
            planInfo = (
                planInfo = (
                    <>
                        <p>Your GPT4 trial has expired. <a href={"#"} onClick={handleUpgrade}>Upgrade</a> to go back to GPT4.</p>
                    </>
                )
            )
            break
        }
        case Plan.PAID_GPT4_ACTIVE: {
            planInfo = (
                planInfo = (
                    <>
                        <p>You are using the GPT4 model! Right on!</p>
                    </>
                )
            )
            break
        }
        case Plan.PAID_GPT4_EXPIRED: {
            planInfo = (
                planInfo = (
                    <>
                        <p>Your GPT4 access has expired. <a href={"#"} onClick={handleUpgrade}>Upgrade</a> to go back to GPT4.</p>
                    </>
                )
            )
            break
        }
    }
    return (
        <>
            <div className={"chatPlanNotification"}>
                {planInfo}
            </div>
            <ChatOutput messages={messages}/>
            <ChatInput onMessageSubmit={onMessageSubmit}/>
        </>
    )
}

export default ChatPanel