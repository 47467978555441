import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

interface Props {
    onMessageSubmit: (message: string) => void;
}

const ChatInput: React.FC<Props> = (props: Props) => {
    const { onMessageSubmit } = props
    const [ inputValue, setInputValue ] = useState('')
    const onChangeInput = (e: { target: { value: string; }; }) => {
        setInputValue(e.target.value)
        
    }

    const handleKeyDown = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            onClickSubmit()
        }
    }

    const onClickSubmit = () => {
        onMessageSubmit(inputValue)
        setInputValue('')
    }

    return (
        <div className={"chat-input-container"}>
            <textarea className={"chat-input-input"} rows={5} value={inputValue} onChange={onChangeInput} onKeyDown={handleKeyDown}/>
            <button className={"chat-input-button"} onClick={onClickSubmit}>
                <FontAwesomeIcon icon={faPaperPlane} size="lg" />
            </button>
        </div>
    )
}

export default ChatInput