import React from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { base16AteliersulphurpoolLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface Props {
    mermaid: string;
}

const MermaidCode: React.FC<Props> = (props: Props) => {
    const { mermaid } = props
    return (
        <div className={"mermaid-code"}>
            <SyntaxHighlighter language="mermaid" style={base16AteliersulphurpoolLight}>
                {mermaid}
            </SyntaxHighlighter>
        </div>
    )
}

export default MermaidCode