// @ts-ignore
import { SSE } from "sse";
import {Message} from "./ChatPanel/ChatOutput";

const url = "https://api.designducky.ai/chat";

const chatCompletions = (messages: Message[], token: string | undefined) => {
    let data = {
        messages: messages
    };
    let headers = {
        "content-type": "application/json"
    }
    if (token) {
        // @ts-ignore
        headers['Authorization'] = `Bearer ${token}`
    }
    return new SSE(url, {
        method: "POST",
        payload: JSON.stringify(data),
        headers: headers
    });
}

export default chatCompletions