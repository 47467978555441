import {Message} from "../ChatPanel/ChatOutput";
import {ChatMessageRole} from "../ChatPanel/ChatMessage";
import {IGptBuddy} from "../GptBuddy/IGptBuddy";

const addFormatReminder = (messages: Message[], gptInstance: IGptBuddy) => {
    messages.pop() // remove empty assistant message
    if (gptInstance.FORMAT_REMINDER && gptInstance.FORMAT_REMINDER.length) {
        messages.push({role: ChatMessageRole.SYSTEM, content: gptInstance.FORMAT_REMINDER})
    }
    return messages
}
const eIsValid = (e: {data: string}) => e && e.data && e.data.trim().length
const eIsDoneToken = (e: {data: string}) => e.data === "[DONE]"
const responseContentIsValid = (response: { choices: string | any[]; }) => response && response.choices && response.choices.length && response.choices[0].delta && response.choices[0].delta.content

export type SendChatOptions = {
    onMessageChunkReceived: (messageChunk: string) => void,
    onMermaidChunkReceived: (mermaidChunk: string) => void,
    onStreamComplete: () => void
}

const sendChat = (
    messages: Message[],
    options: SendChatOptions,
    gptInstance: IGptBuddy,
    token: string | undefined
) => {
    return gptInstance.sendChat(
        messages,
        options,
        gptInstance,
        addFormatReminder,
        eIsValid,
        eIsDoneToken,
        responseContentIsValid,
        token
    )
}

const getNewDiagram = (
    messages: Message[],
    gptInstance: IGptBuddy,
    error: string,
    token: string | undefined
): Promise<string> => {
    const clonedMessages: Message[] = Object.assign([], messages);
    const newMessage = {
        role: ChatMessageRole.SYSTEM,
        content: gptInstance.getDiagramErrorPrompt(error)
    }
    clonedMessages.push(newMessage)
    return new Promise((resolve) => {
        let newMermaidDiagram = ''
        sendChat(
            clonedMessages,
            {
                onMessageChunkReceived: () => {},
                onMermaidChunkReceived: (mermaidChunk: string) => {
                    newMermaidDiagram += mermaidChunk
                },
                onStreamComplete: async () => {
                    resolve(newMermaidDiagram)
                }
            },
            gptInstance,
            token
        )
    })
}

const StreamUtils = { sendChat, getNewDiagram }
export default StreamUtils