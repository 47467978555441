import React from 'react';
import '../App.css';
import MainContent from "../MainContent";
import PageLayout from "../PageLayout";

function HomePage() {
    return (
        <PageLayout>
            <MainContent/>
        </PageLayout>
    );
}

export default HomePage;
