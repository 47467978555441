import {IGptBuddy} from "./IGptBuddy";
import {ChatMessageRole} from "../ChatPanel/ChatMessage";
import {AbstractGptBuddy} from "./AbstractGptBuddy";
import {Message} from "../ChatPanel/ChatOutput";
import {SendChatOptions} from "../utils/streamUtils";
import chatCompletions from "../api";

class Gpt3_5Buddy extends AbstractGptBuddy implements IGptBuddy {
    SYSTEM_CONTEXT = `Imagine you and I are collaborating on the system design for a new software tool. I will tell you what I am trying to build. If the requirements are unclear, ask me clarifying questions. I may also ask you for ideas on how to build the system during our conversation. We are partners and together we will architect this software system.`;
    FORMAT_REMINDER = ``;

    DIAGRAM_ERROR = `It seems there is an error in your last Mermaid diagram, or you forgot to include it. Please correct the mistake and remember to follow the format we discussed earlier:
1. Wrap the mermaid diagram in three backticks (\`\`\`)
2. Do not include any other text.`
    usesFormatReminder = () => false
    initialMessages = [
        {
            role: ChatMessageRole.SYSTEM,
            content: this.SYSTEM_CONTEXT
        },
        {
            role: ChatMessageRole.ASSISTANT,
            content: this.DUCKY_INTRO
        }
    ]

    formatDiagram = (diagram: string) => {
        if (diagram.startsWith("graph")) {
            return diagram
        }
        if (diagram.startsWith("mermaid")) {
            return diagram.replace("mermaid", "")
        }
        if (diagram.startsWith("```mermaid") && diagram.endsWith("```")) {
            return diagram.replace("```mermaid", "").replace("```", "").trim()
        }
        if (diagram.startsWith("```") && diagram.endsWith("```")) {
            return diagram.replaceAll("```", "").trim()
        }
        let mermaidPieces = diagram.split("```")
        if (mermaidPieces.length > 0) {
            return mermaidPieces[1].trim()
        }
        return diagram.trim()
    }

    sendChat = (
        messages: Message[],
        options: SendChatOptions,
        gptInstance: IGptBuddy,
        addFormatReminder: (messages: Message[], gptInstance: IGptBuddy) => void,
        eIsValid: (e: { data: string }) => "" | number,
        eIsDoneToken: (e: { data: string }) => boolean,
        responseContentIsValid: (response: { choices: string | any[] }) => "" | 0,
        token: string | undefined
    ) => {
        const {onMessageChunkReceived, onMermaidChunkReceived, onStreamComplete} = options
        const latestConversation = Object.assign([], messages)

        let gptResponse = ''

        // once chat stream is complete, request new diagram before sending onStreamComplete
        const internalChatStreamComplete = () => {
            latestConversation.push(
                {
                    role: ChatMessageRole.ASSISTANT,
                    content: gptResponse
                },
                {
                    role: ChatMessageRole.USER,
                    content: `Please send me a Mermaid diagram representing the architecture we have come up with so far. Wrap the mermaid diagram in three backticks (\`\`\`) in your response. Do not include any other text in your response.`
                }
            )
            const mermaidStream = chatCompletions(latestConversation, token)
            mermaidStream.addEventListener('message', (e: {data: string}) => {
                if (!eIsValid(e)) return
                if (eIsDoneToken(e)) {
                    onStreamComplete()
                    return
                }

                const response = JSON.parse(e.data);
                if (!responseContentIsValid(response)) return

                const newContent = response.choices[0].delta.content
                gptResponse += newContent
                onMermaidChunkReceived(newContent)
            })
            mermaidStream.stream()
        }


        // conversation
        const chatGptStream = chatCompletions(latestConversation, token)
        chatGptStream.addEventListener('message', (e: {data: string}) => {
            if (!eIsValid(e)) return
            if (eIsDoneToken(e)) {
                internalChatStreamComplete()
                return
            }

            const response = JSON.parse(e.data);
            if (!responseContentIsValid(response)) return

            const newContent = response.choices[0].delta.content
            gptResponse += newContent
            onMessageChunkReceived(newContent)
        })

        chatGptStream.stream()
    }
}

export default Gpt3_5Buddy